.pageloader {
  background-color: rgb(84, 187, 255);
}

.pageloader .requirements {
  text-align: center;
  margin-top: 80px;
}

.svg-box {
  width: 100%;
  height: 0;
  position: relative;
}

.svg-box > svg {
  position: absolute;
  top: 0;
  left: 0;
}

.nav.tabs a:hover {
  background-color: #1496ed;
}

.tag:not(body).is-info,
.button.is-info {
  background-color: #54bbff;
  font-weight: bold;
}

body,
button,
input,
select,
textarea {
  font-family: "Noto Sans JP", BlinkMacSystemFont, -apple-system, "Segoe UI",
    Roboto, Oxygen, Ubuntu, Cantarell, "Fira Sans", "Droid Sans",
    "Helvetica Neue", Helvetica, Arial, sans-serif;
}
